import {
  TReportAnalyticsContext,
  TReportFilterType,
} from "src/data/analytics/types/analyticsTypes"

import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TMoldAnalyticsEventTypes =
  | "Analytics Filter Applied"
  | "View Graph Clicked"
  | "Graph Filter Applied"
  | "Tip Link Clicked"

export type TMoldAnalyticsProperties = {
  filter_type?: TReportFilterType
  context: TReportAnalyticsContext
}

function usePostAnalyticsTrackingEvent<TEventProperties>(
  event: TMoldAnalyticsEventTypes
) {
  return usePostTrackEventWithParams<
    TMoldAnalyticsEventTypes,
    TEventProperties
  >({
    event,
  })
}

export function useTrackAnalyticsFilterApplied() {
  return usePostAnalyticsTrackingEvent<TMoldAnalyticsProperties>(
    "Analytics Filter Applied"
  )
}

export function usePostViewGraphClicked() {
  return usePostAnalyticsTrackingEvent<TMoldAnalyticsProperties>(
    "View Graph Clicked"
  )
}

export function usePopupGraphFilterApplied() {
  return usePostAnalyticsTrackingEvent<TMoldAnalyticsProperties>(
    "Graph Filter Applied"
  )
}

export function useTipLinkClicked() {
  return usePostAnalyticsTrackingEvent<TMoldAnalyticsProperties>(
    "Tip Link Clicked"
  )
}
